import { render, staticRenderFns } from "./Deadline.vue?vue&type=template&id=06e452c6&"
import script from "./Deadline.vue?vue&type=script&lang=js&"
export * from "./Deadline.vue?vue&type=script&lang=js&"
import style0 from "./Deadline.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VFileInput,VSpacer,VTooltip})
